import React, { useState } from 'react';

import './Accordion.css';

export const Accordion = (props) => {
    const { content } = props;

    const [activeIndex, setActiveIndex] = useState(-1);

    return (
        <div className="accordion">
            {
                content && content.map((c, k) => {
                    const isShown = k === activeIndex ? true : false;
                    const ariaExpanded = k === activeIndex ? true : false;

                    return <div className="accordionItem">
                        <div className="accordionItemContainer">
                            <dt>
                                <button
                                    className={`accordionItemButton arial-bold-lg ${isShown ? 'active' : ''}`}
                                    aria-expanded={ariaExpanded}
                                    aria-controls={`accordionItem-${k}`}
                                    onClick={() => setActiveIndex(isShown ? -1 : k)}
                                    onTouchEnd={() => setActiveIndex(isShown ? -1 : k)}
                                >
                                    { c.title } <span>{isShown ? '✕' : '➜' }</span>
                                </button>
                            </dt>
                            {
                                isShown && <dd>
                                    { c.listElements }
                                </dd>
                            }
                        </div>
                    </div>
                })
            }
        </div>
    );
}