import React, { useState } from 'react';

const Header = () => {

    const [isMenuOpened, setMenuOpened] = useState(false);

    return (
        <header id="mainHeader">
            <a className="linkHome" href="/">
                <img src="https://hiasecure.com/wp-content/themes/hia-vitrine-v2/static/media/logo-HIA-bleu.2b138828.svg" alt="HIAsecure"/>
                <span>The ultimate smart security</span>
            </a>
            <a className="" href="https://hiasecure.com/nos-solutions">Notre solution</a>
            <a className="" href="https://hiasecure.com/nos-secteurs">Nos secteurs</a>
            <a className="" href="https://hiasecure.com/nos-offres">Nos offres</a>
            <a href="https://hiasecure.com/#partenaires">Nos partenaires</a>
            <div>
                <a href="https://showroom.hiasecure.com/" target="_blank" rel="noopener noreferrer" className="framedLink">Mon espace</a>
                <a className="framedLink full" href="https://hiasecure.com/nous-contacter">Nous contacter</a>
            </div>
            <div className="menuBurger " onClick={() => setMenuOpened(!isMenuOpened)}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className={`mobileMenu ${isMenuOpened ? '' : 'closedMenu'}`}>
                <nav className="menuContainer">
                    <a className="" href="https://hiasecure.com/nos-solutions">Notre solution</a>
                    <a className="" href="https://hiasecure.com/nos-secteurs">Nos secteurs</a>
                    <a className="" href="https://hiasecure.com/nos-offres">Nos offres</a>
                    <a href="https://hiasecure.com/#partenaires">Nos partenaires</a>
                    <div>
                        <a href="https://showroom.hiasecure.com/" target="_blank" rel="noopener noreferrer" className="framedLink">Mon espace</a>
                        <a className="framedLink full" href="https://hiasecure.com/nous-contacter">Nous contacter</a>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;