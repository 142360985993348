import React from 'react';

import './ShowroomBanner.css';

export const ShowroomBanner = () => {
    return (
        <div id="showroomBanner">
            <p>Showroom</p>
            <div className="showroomBannerGradientBorder"></div>
        </div>
    );
}